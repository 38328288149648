// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consalting-js": () => import("./../src/pages/consalting.js" /* webpackChunkName: "component---src-pages-consalting-js" */),
  "component---src-pages-fish-js": () => import("./../src/pages/fish.js" /* webpackChunkName: "component---src-pages-fish-js" */),
  "component---src-pages-grain-js": () => import("./../src/pages/grain.js" /* webpackChunkName: "component---src-pages-grain-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trade-js": () => import("./../src/pages/trade.js" /* webpackChunkName: "component---src-pages-trade-js" */)
}

